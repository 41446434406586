
















import { Component, Mixins, Watch, Vue } from 'vue-property-decorator';
import FormMixin from '@/mixins/form';

@Component({
  props: {
    methods: {
      type: Array,
      default: () => {
        return ['ga'];
      },
    },
    onSubmitSms: {
      type: Function,
      default: (smsCode: string) => {},
    },
    onSubmitGa: {
      type: Function,
      default: (gaCode: string) => {},
    },
  },
})
export default class TwoFaAuthenticatorForm extends Mixins(FormMixin) {
  methods: string[];
  onSubmitGa: Function;

  selectedMethod = '';
  faCode = '';
  isLoading = false;
  formErrors = { ga: false, sms: false };

  get isGaMethodAvailable(): boolean {
    return this.methods.includes('ga');
  }

  get isSmsMethodAvailable(): boolean {
    return this.methods.includes('sms');
  }

  get errorGa(): string {
    return this.formErrors.ga ? 'Invalid Authentication Code' : '';
  }

  submitGaCode(): any {
    if (this.isLoading) return;

    this.isLoading = true;
    return Promise.resolve()
      .then(() => {
        return this.onSubmitGa(this.faCode);
      })
      .catch(() => {
        this.formErrors.ga = true;
      })
      .finally(() => {
        this.isLoading = false;
      });
  }

  selectMethod(method: string) {
    this.selectedMethod = method;
    // if (method !== 'sms' && this.delaySendSubsc) {
    //   this.delaySendSubsc.unsubscribe();
    //   this.delaySendSubsc = undefined;
    // }

    // if (method === 'sms' && this.firstSendSMS) {
    //   this.delaySendSMS();
    // }
  }

  mounted() {
    if (this.methods.length) {
      this.selectMethod(this.methods[0]);
    }
  }

  @Watch('faCode')
  onFaCodeChange(newVal: string) {
    this.formErrors.ga = false;
    if (newVal && newVal.length === 6) {
      this.submitGaCode();
    }
  }
}
