









import { Component, Prop, Vue } from 'vue-property-decorator';
import { verifyTwoFa } from '@/apis/twoFa.api';
import TwoFactorForm from '@/components/shared/TwoFactorForm.vue';
import {
  errorPopupHandler,
  errorCatchHandler,
  loadings,
} from '@/utils/decorators/VueTimmer';

@Component({
  components: {
    TwoFactorForm
  }
})
export default class TwoFactorPage extends Vue {
  onSubmitGa(faCode: string) {
    return verifyTwoFa(faCode)
      .then(() => {
        this.$router.push({ name: 'account_detail' });
      });
  }

  logout() {
    this.$store.dispatch('account/logout');
    this.$router.push({ name: 'login' });
  }
}
